import React from 'react'
import PropTypes from 'prop-types'
import '../styles/components/DescriptedImage.css'

export default function DescriptedImage(props) {
    const {
        title = 'DEFAULT TITLE',
        description = "Default description",
        enableDarkTheme = false,
        switchElements = false,
        imgUrl = ''
    } = props

  return (
    <div className='descripted-img-container'>
        {!switchElements &&  <div className='img-container' style={{ backgroundImage: `url(${imgUrl})`}}/>}
        <div className={`titled-description ${enableDarkTheme? 'dark' : ''}`}>
            <h2 className='title'>{title}</h2>
            <p className='description'>{description}</p>
        </div>
        {switchElements &&  <div className='img-container' style={{ backgroundImage: `url(${imgUrl})`}}/>}
    </div>
  )
}

DescriptedImage.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    imgUrl: PropTypes.any,
    enableDarkTheme: PropTypes.bool,
    switchElements: PropTypes.bool
}