import emailjs from '@emailjs/browser'

export default function sendEmail(form) {
    emailjs.sendForm('service_gu45kma', 'template_tsk2zqh', form, {
        publicKey: 'SO0Ld6wHoyZXTddwF',
    }).then(() => {
        const formView = document.getElementById('form-view')
        formView.style.display = 'none'
        const successView = document.getElementById('success-view')
        successView.style.display = 'flex'
    },
    (error) => {
        const formView = document.getElementById('form-view')
        formView.style.display = 'none'
        const failedView = document.getElementById('failed-view')
        failedView.style.display = 'flex'
    })
}
    
