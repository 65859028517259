import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/components/inputs/TitledTextArea.css'

export default function TitledTextArea(props) {
    const {
      title = 'Default title',
      rows = 7,
      value = '',
      onChange = () => {}
    } = props
  return (
    <div className='titled-textarea'>
        <label className='title'>{title}</label>
        <textarea
          rows={rows}
          value={value}
          onChange={e => onChange(e.target.value)}
          id={props?.id}
          name={props?.name}
        />
    </div>
  )
}

TitledTextArea.propTypes = {
    title: PropTypes.string,
    rows: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string,
    name: PropTypes.string
}