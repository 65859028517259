import React from 'react'
import '../styles/components/IconTitledCard.css'
import PropTypes from 'prop-types'

export default function IconTitledCard(props) {
    const {title = 'Default data', description = 'Default data', icon = <></>} = props
  return (
    <div className='card'>
        <div className='ico-sample'>{icon}</div>
        <h4 className='title'>{title}</h4>
        <div className='description'>{description}</div>
    </div>
  )
}

IconTitledCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.element
}
