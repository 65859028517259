import React from 'react'
import PropTypes from 'prop-types'

export default function BurgerIcon(props) {
    const {
        sizeRem = 1,
        color = "#ffffff"
    } = props
  return (
    <svg
        width={`${sizeRem}rem`}
        height={`${sizeRem}rem`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke={color}
    >
        <path d="M4 18L20 18" stroke-width="2" stroke-linecap="round"/>
        <path d="M4 12L20 12" stroke-width="2" stroke-linecap="round"/>
        <path d="M4 6L20 6" stroke-width="2" stroke-linecap="round"/>
    </svg>
  )
}

BurgerIcon.propTypes = {
    sizeRem: PropTypes.number,
    color: PropTypes.string
}