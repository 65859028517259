import React from 'react'
import PropTypes from 'prop-types'

export default function CheckIcon(props) {
    const {
        sizeRem = 1,
        color = "#ffffff"
    } = props

  return (
    <svg
        width={`${sizeRem}rem`}
        height={`${sizeRem}rem`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke={color}
    >
        <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

CheckIcon.propTypes = {
    sizeRem: PropTypes.number,
    color: PropTypes.string
}