import React from 'react'
import '../../styles/components/buttons/RollUpBtn.css'
import ChevronUpIcon from '../../icons/ChevronUpIcon'
import PropTypes from 'prop-types'

export default function RollUpBtn(props) {
    const {
        onClick = () => {},
        link = ''
    } = props
  return (
    <a href={link}>
        <button className='roll-up-btn' onClick={onClick}>
            <ChevronUpIcon sizeRem={2}/>
        </button>
    </a>
  )
}

RollUpBtn.propTypes = {
    onClick: PropTypes.func,
    link: PropTypes.string
}