import React from 'react'
import PropTypes from 'prop-types'

export default function FailedIcon(props) {
    const {
        sizeRem = 1,
        color = "#ffffff"
    } = props

  return (
    <svg
        width={`${sizeRem}rem`}
        height={`${sizeRem}rem`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke={color}
    >
        <path d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

FailedIcon.propTypes = {
    sizeRem: PropTypes.number,
    color: PropTypes.string
}