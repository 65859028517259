import React, { useState } from 'react'
import '../styles/nav/SideMenu.css'
import NavBtn from '../components/buttons/NavBtn'
import { CSSTransition } from 'react-transition-group'
import BurgerIcon from '../icons/BurgerIcon'
import { lit } from '../constants/literals'

export default function SideMenu(props) {
    const {lang = 'RU'} = props
    const [menuStatus, setMenuStatus] = useState(false)
    const toggleSideMenu = () => setMenuStatus(!menuStatus)

  return (
    <div className='side-menu-container'>
        <button
            className='side-menu-toggler'
            onClick={toggleSideMenu}
        >
            <BurgerIcon sizeRem={1.75}/>
        </button>

        <CSSTransition
            in={menuStatus}
            timeout={300}
            classNames='side-anim'
            unmountOnExit
        >
            <div className={`side-menu ${menuStatus ? 'opened' : ''}`}>
                <NavBtn onClick={() => setMenuStatus(false)} title={lit?.[lang]?.main} href='#nav-link-1'/>
                <NavBtn onClick={() => setMenuStatus(false)} title={lit?.[lang]?.about} href='#nav-link-2'/>
                <NavBtn onClick={() => setMenuStatus(false)} title={lit?.[lang]?.equipment} href='#nav-link-3'/>
                <NavBtn onClick={() => setMenuStatus(false)} title={lit?.[lang]?.services} href='#nav-link-4'/>
                <NavBtn onClick={() => setMenuStatus(false)} title={lit?.[lang]?.contacts} href='#nav-link-6'/>
            </div>
        </CSSTransition>
    </div>
  )
}