export const lit = {
    'RU': {
            title1: 'Аддитивные промышленные технологии',
            subtitle1: 'Изготовление оснастки и готовых изделий из композитных материалов для промышленных предприятий способом 3д печати',
            contactUs: 'Заказать звонок',

            main: 'Главная',
            about: 'О компании',
            equipment: 'оборудование',
            services: 'услуги',
            contacts: 'контакты',

            title2: 'О компании',
            title3: 'Производственная база',
            title4: 'Промышленный многоосевой принтер на базе робота',
            title5: 'Фрезерный станок CMS Ares 6018',
            title6: 'Контрольно - измерительное оборудование Faro Quantum Arm',
            title7: 'Услуги нашей компанией',

            desc1: <div>
                <p>ООО «АПТ» с 17 декабря 2021 года является резидентом Особой Экономической Зоны Дубна (свидетельство № 20215109215210022 от 17.12.2021 года)</p>
                ООО «АПТ» осуществляет технико-внедренческую и производственную деятельность в направлении:
                <ul>
                    <li>разработка, проектирование, производство, реализация оснастки и других изделий, в том числе из полимерных материалов. Оснастка применяется для изготовления деталей в авиационной, космической, железнодорожной и иной отрасли;</li>
                    <li>разработка, проектирование, производство, реализация станков с числовым программным управлением, в том числе 3D-принтеров, а также документации и комплектующих к ним.</li>
                </ul>
                Компетенция предприятия в указанной сфере подтверждается наличием управленческого и квалифицированного рабочего персонала, имеющего практический опыт в организации производственного процесса по своевременному, с высоким качеством выпуску требуемой оснастки из полимерных материалов.
                </div>,
            desc2: 'Большой промышленный многоосевой принтер на базе робота, производительность экструдера более 15кг/час для крупногабаритной 3D печати. Данная модель будет превосходным решением для промышленного предприятия. Промышленный 3D-принтер, осуществляет печать по технологии FJF — послойным наращиванием композитного материала. Область построения 6000х3000х2000 мм обеспечивает печать форм крупногабаритных деталей. Позволяет произвести форму в течении суток без дополнительных затрат. Конструкция принтера и принятые проектные решения обеспечивают надежность, точность, быстроту печати, открывают возможности производства форм любой степени сложности (искривленная конфигурация, внутренние полости, большое количество уникальных сечений в различных плоскостях).',
            desc3: <div>
                <p>Область фрезерной обработки 6000x1800x1200.</p>
                <p>Обрабатывающие центры портального типа модели Ares предназначены для высокоскоростной 3-х или 5-ти осевой обработки цветных металлов, композитных материалов, эпоксидных и полиуретановых смол, углепластиков, древесины и материалов, имеющих сотовую структуру.</p>
                <p>Высокие динамические параметры станков обеспечены применением жесткой портальной конструкции, современными высокомоментными сдвоенными осевыми приводами и низкой инерционностью подвижных узлов.</p>
            </div>,
            desc4: <div>
                <p>Новая КИМ типа рука FARO Quantum является наиболее инновационной мобильной координатной измерительной машиной в мире, позволяющей вам просто и эффективно проводить оценку качества вашей продукции путем 3D измерений, анализа данных, сравнения с CAD моделью, сертификации стендов, обратного инжиниринга и многого другого.</p>
                <p>Quantum - первая «рука», которая соответствует новому и наиболее требовательному международному стандарту качества измерений ISO10360-12:2016. Quantum устанавливает новую планку качества в отрасли и продолжает традицию FARO обеспечивать максимальную уверенность и надежность работы в любых условиях.</p>
            </div>,
            desc5: <div>
                <ul>
                        <li>ИЗГОТОВЛЕНИЕ ОСНАСТКИ ДЛЯ КОМПОЗИТНОГО И ЗАГОТОВИТЕЛЬНО- ШТАМПОВОЧНОГО ПРОИЗВОДСТВА</li>
                        <li>ПРОЕКТИРОВАНИЕ</li>
                        <li>ИНТЕГРАЦИЯ</li>
                        <li>СЕРВИСНОЕ ОБСЛУЖИВАНИЕ</li>
                    </ul>
                    <p>Технология позволяет существенно сократить сроки и затраты на подготовку производства новых и серийных изделий. Время, необходимое для изготовления форм и компонентов, может быть сокращено на 75% по сравнению с традиционным производством.</p>
                    <p>Производственные мощности АПТ позволяют провести полный цикл по изготовлению деталей.</p>
            </div>,
            name: 'Ваше имя',
            name_placeholder:  'Введите имя',
            email: 'Ваш E-mail',
            email_placeholder: 'Введите e-mail',
            phone: 'Ваш телефон',
            phone_placeholder: '+7 (ХХХ) ХХХ - ХХ - ХХ',
            messageTitle: 'Тема сообщения',
            messageTitle_placeholder: 'Тема сообщения',
            messageText: 'Ваше сообщение',

            send_message: 'Отправить',
            feedback: 'Обратная связь',
            contacts_data: 'Контактны данные',
            urid_data: 'Юридические данные',
            adress: 'Наш адресс',
            data1: <div style={{display: 'flex', gap: '.5rem', flexDirection: 'column'}}><a href="tel:+74957284606">+7 (495) 728-46-06</a><a href="mailto:info@apt-3d.ru">info@apt-3d.ru</a></div>,
            data2: <div>ИНН / КПП 9701176841 / 501001001 <br/>ОГРН 1217700212960 </div>,
            data3: <div>141983, Московская обл, г. Дубна,<br/>ул. Флерова, д. 11, офис 69</div>,

            footer_text: '© 2024 АПТ - Изготовление оснастки и готовых изделий из композитного материала для промышленных предприятий',
            message_success_send: 'Сообщение отправлено',
            message_failed_send: 'Не удалось отправить сообщение'
    },

    'EN': {
            title1: 'Additive Industrial Technologies',
            subtitle1: 'Large Scale FGF 3D printing, manufacturing of equipment from polymer materials for composite products',
            contactUs: 'Request a call',
        
            main: 'Home',
            about: 'Company',
            equipment: 'Solutions ',
            services: 'Servicies',
            contacts: 'Contact',
        
            title2: 'Company',
            title3: 'Facility equipment',
            title4: 'Large scale robotic FGF 3D printer',
            title5: 'Milling machine CMS Ares 6018',
            title6: 'Coordinate measuring machine (CMM) Faro Quantum',
            title7: 'Our servicies',
        
            desc1: <div>
						<p>Since December 17, 2021, APT LLC has been a resident of the Dubna Special Economic Zone (certificate No. 20215109215210022 dated December 17, 2021), </p>
						APT LLC carries out technical implementation and production activities in the direction of:
						<ul>
							<li>development, design, production, sale of equipment and other products, including those made of polymeric materials. The tooling is used for manufacturing parts in the aviation, space, railway and other industries;</li>
							<li>development, design, production, sale of CNC machines, including 3D printers, as well as documentation and components for them.</li>
						</ul>
						The enterprise's competence in this area is confirmed by the presence of management and qualified workers with practical experience in organizing the production process for the timely, high-quality release of the required tooling from polymeric materials.
					</div>,
			desc2: 'A large industrial multi-axis printer based on a robot, the extruder capacity is more than 15 kg / hour for large-scale 3D printing. This model will be an excellent solution for an industrial enterprise. Industrial 3D printer, prints using FJF technology - layer-by-layer build-up of composite material. The build area of ​​​​6000x3000x2000 mm provides printing of forms of large-sized parts. Allows you to produce a form within 24 hours without additional costs. The design of the printer and the adopted design solutions ensure reliability, accuracy, speed of printing, open up the possibility of producing forms of any complexity (curved configuration, internal cavities, a large number of unique sections in different planes).',
            desc3:<div>
					<p>Milling area 6000x1800x1200.</p>
					<p>Ares portal-type machining centers are designed for high-speed 3- or 5-axis machining of non-ferrous metals, composite materials, epoxy and polyurethane resins, carbon fiber reinforced plastics, wood and materials with a honeycomb structure.</p>
					<p>High dynamic parameters of the machines are ensured by the use of a rigid portal structure, modern high-torque dual axial drives and low inertia of moving units.</p>
				</div>,
			desc4: <div>
					<p>The new FARO Quantum arm CMM is the world's most innovative mobile coordinate measuring machine, allowing you to easily and effectively assess the quality of your products through 3D measurement, data analysis, CAD comparison, rig certification, reverse engineering and more.</p>
					<p>Quantum is the first arm to comply with the new and most demanding international measurement quality standard ISO10360-12:2016. Quantum sets a new bar for quality in the industry and continues FARO's tradition of providing maximum confidence and reliability in any environment.</p>
					</div>,
			desc5: <div>
						<ul>
							<li>PRODUCTION OF TOOLS FOR COMPOSITE AND PREPAINT-STAMPING PRODUCTION</li>
							<li>DESIGN</li>
							<li>INTEGRATION</li>
							<li>SERVICE MAINTENANCE</li>
						</ul>
						<p>The technology allows to significantly reduce the time and costs for preparation of production of new and serial products. The time required for production of forms and components can be reduced by 75% compared to traditional production.</p>
						<p>The production capacity of APT allows to carry out a full cycle of production of parts.</p>
					</div>,
        
        
            name: 'Your Name',
            name_placeholder: 'Text a Name',
            email: 'Your E-mail',
            email_placeholder: 'Text an e-mail',
            phone: 'Your Phone number',
            phone_placeholder: '+x (ХХХ) ХХХ - ХХ - ХХ',
            messageTitle: 'Request subject',
            messageTitle_placeholder: 'Text a subject',
            messageText: 'Your request',
        
            send_message: 'Send',
            feedback: 'Feedback',
            contacts_data: 'Contacts',
            urid_data: 'Legal address',
            adress: 'Facility adress',
            data1: <div style={{display: 'flex', gap: '.5rem', flexDirection: 'column'}}><a href="tel:+74957284606">+7 (495) 728-46-06</a><a href="mailto:info@apt-3d.ru">info@apt-3d.ru</a></div>,
            data2: <div>ИНН / КПП 9701176841 / 501001001 <br/>ОГРН 1217700212960 </div>,
            data3: <div>141983, Moscow region, t. Dubna,<br/>Flerova st., 11, office 69</div>,
        
            footer_text: '© 2024 APT LLC - Manufacturing of tooling and finished products from composite material for industrial enterprises'
       }
}