import React from 'react'
import '../../styles/components/buttons/ChangeLanguageBtn.css'

export default function ChangeLanguageBtn(props) {
    const { selected_lang = 'RU', switchLang = () => {} } = props
  return (
    <div className='change-lang-btn'>
        <button
            className={selected_lang == 'RU' ? 'active' : ''}
            onClick={() => switchLang('RU')}
        >RU</button>
        <button
            className={selected_lang == 'EN' ? 'active' : ''}
            onClick={() => switchLang('EN')}
        >EN</button>
    </div>
  )
}
