import React, { useCallback, useEffect, useRef, useState } from 'react'
import '../styles/components/ContactFormBlock.css'
import TitledInput from './inputs/TitledInput'
import TitledTextArea from './inputs/TitledTextArea'
import sendEmail from '../SendEmail'
import CheckIcon from '../icons/CheckIcon'
import FailedIcon from '../icons/FailedIcon'
import { lit } from '../constants/literals'

export default function ContactFormBlock(props) {
    const {lang = 'RU'} = props
    const [nameField, setNameField] = useState('')
    const [phoneField, setPhoneField] = useState('')
    const [emailField, setEmailField] = useState('')
    const [messageTitleField, setMessageTitleField] = useState('')
    const [messageTextField, setMessageTextField] = useState('')

    const [nameCorrect, setNameCorrect] = useState(false)
    const [phoneCorrect, setPhoneCorrect] = useState(false)
    const [emailCorrect, setEmailCorrect] = useState(false)
    const [messageTextCorrect, setMessageTextCorrect] = useState(false)
    const [messageTitleCorrect, setMessageTitleCorrect] = useState(false)
    const [sendClicked, setSendClicked] = useState(false)
    const onlyLetterReg = (str) => str.replace(/[^a-zа-яё]/gi, '')
    const emailRegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    const onPhoneChange = str => setPhoneField(str.replace(/[a-z]/gi, ''))

    const form = useRef()

    const changeStateInput = (id, correct) => {
        if (!correct) {
            const input = document.getElementById(id)
            input.style.borderColor = 'red'
            input.style.outlineColor = 'red'
        } else {
            const input = document.getElementById(id)
            input.style.borderColor = '#fcfcfc'
            input.style.outlineColor = 'var(--primary-color)'
        }
    }
    useEffect(() => {
        setNameCorrect(nameField.length > 0)
        setPhoneCorrect(phoneField.length > 7)
        setEmailCorrect(emailRegExp.test(emailField))
        setMessageTitleCorrect(messageTitleField.length > 0)
        setMessageTextCorrect(messageTextField.length > 0)

        if (sendClicked) {
            changeStateInput('name-input', nameCorrect)
            changeStateInput('phone-input', phoneCorrect)
            changeStateInput('email-input', emailCorrect)
            changeStateInput('title-input', messageTitleCorrect)
            changeStateInput('text-input', messageTextCorrect)
        }
    }, [nameField, nameCorrect,
        phoneField, phoneCorrect,
        emailField, emailCorrect,
        messageTextField, messageTextCorrect,
        messageTitleField, messageTitleCorrect,sendClicked])

    const sendForm = useCallback((e) => {
        e.preventDefault()
        setSendClicked(true)
        if (nameCorrect && phoneCorrect && emailCorrect && messageTextCorrect && messageTitleCorrect) {
            sendEmail(e.target)
        }
    }, [
        nameField, nameCorrect,
        phoneField, phoneCorrect,
        emailField, emailCorrect,
        messageTextField, messageTextCorrect,
        messageTitleField, messageTitleCorrect
    ])

  return (
    <div className='contact-from-container' id='nav-link-5'>
        <div id='form-view' className='form-container'>
            <h1 className='feedback-title'>{lit?.[lang]?.feedback}</h1>
            <form ref={form} className='contact-form' onSubmit={sendForm}>
                <div className='row'>
                    <div className='col'>
                        <TitledInput
                            title={lit?.[lang]?.name}
                            placeholder={lit?.[lang]?.name_placeholder}
                            value={nameField}
                            onChange={(v) => setNameField(onlyLetterReg(v))}
                            id='name-input'
                            name='name'
                        />
                        <TitledInput
                            title={lit?.[lang]?.phone}
                            placeholder={lit?.[lang]?.phone_placeholder}
                            value={phoneField}
                            onChange={onPhoneChange}
                            id='phone-input'
                            name='phone'
                        />
                    </div>
                    <div className='col'>
                        <TitledInput
                            title={lit?.[lang]?.email}
                            placeholder={lit?.[lang]?.email_placeholder}
                            value={emailField}
                            onChange={setEmailField}
                            id='email-input'
                            name='email'
                        />
                        <TitledInput
                            title={lit?.[lang]?.messageTitle}
                            placeholder={lit?.[lang]?.messageTitle_placeholder}
                            value={messageTitleField}
                            onChange={setMessageTitleField}
                            id='title-input'
                            name='title'
                        />
                    </div>
                </div>
                <div className='row'>
                    <TitledTextArea
                        title={lit?.[lang]?.messageText}
                        rows={9}
                        value={messageTextField}
                        onChange={setMessageTextField}
                        id='text-input'
                        name='message'
                    />
                </div>
                <button
                    className='send-form-btn'
                    type='submit'
                >{lit?.[lang]?.send_message}</button>
            </form>
        </div>
        <div id='success-view' className='success-send'>
            <CheckIcon sizeRem={5} color='#6cc070'/>
            <label>{lit?.[lang]?.message_success_send}</label>
        </div>
        <div id='failed-view' className='success-send'>
            <FailedIcon sizeRem={5} color='#ff6633'/>
            <label>{lit?.[lang]?.message_failed_send}</label>
        </div>
    </div>
  )
}
