import React from 'react'
import '../../styles/components/buttons/ContactUsBtn.css'
import { lit } from '../../constants/literals'

export default function ContactUsBtn(props) {
  const {lang = 'RU'} = props
  return (
    <a href="#nav-link-5">
      <button className='contact-us-btn'>
        {lit?.[lang]?.contactUs.toUpperCase()}
      </button>
    </a>
  )
}
