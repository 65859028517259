import React from 'react'
import PropTypes from 'prop-types'

export default function ChevronUpIcon(props) {
    const {
        sizeRem = 1,
        color = "#ffffff"
    } = props

  return (
    <svg
        width={`${sizeRem}rem`}
        height={`${sizeRem}rem`}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke={color}
    >
        <path d="M6 15L12 9L18 15" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

ChevronUpIcon.propTypes = {
    sizeRem: PropTypes.number,
    color: PropTypes.string
}