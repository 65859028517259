import React from 'react'
import '../../styles/components/inputs/TitledInput.css'
import PropTypes from 'prop-types'

export default function TitledInput(props) {
    const {
      title = 'Default',
      placeholder = 'Placeholder..',
      value = '',
      onChange = () => {}
    } = props
  return (
    <div className='titled-input'>
        <label className='title'>{title}</label>
        <input
          type='text'
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
          id={props?.id}
          name={props?.name}
        />
    </div>
  )
}

TitledInput.propTypes = {
    title: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    id: PropTypes.string,
    name: PropTypes.string
}