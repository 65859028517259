import React from 'react'
import '../styles/components/FooterBlock.css'
import { lit } from '../constants/literals'

export default function FooterBlock(props) {
  const {lang = 'RU'} = props
  return (
    <div className='footer-block'>
        {lit?.[lang]?.footer_text}
    </div>
  )
}
