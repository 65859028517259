import React from 'react'
import '../../styles/components/buttons/NavBtn.css'
import PropTypes from 'prop-types'

export default function NavBtn(props) {
    const { title = 'DEFAULT', href = '', onClick = () => {} } = props
  return (
    <a className='nav-link-anchor' href={href} onClick={onClick}>
      <button className='nav-btn'>
        {title.toUpperCase()}
      </button>
    </a>
  )
}

NavBtn.propTypes ={
    title: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func
}
