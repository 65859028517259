import React, { useCallback, useEffect, useState } from 'react'
import './styles/MainPage.css'
import TopMenu from './nav/TopMenu'
import PresentBlock from './components/PresentBlock'
import AboutBlock from './components/AboutBlock'
import EquipmentBlock from './components/EquipmentBlock'
import ServicesBlock from './components/ServicesBlock'
import ContactFormBlock from './components/ContactFormBlock'
import MapsBlock from './components/MapsBlock'
import FooterBlock from './components/FooterBlock'
import MainBG from './assets/bg_video.mp4'
import RollUpBtn from './components/buttons/RollUpBtn'
import ChangeLanguageBtn from './components/buttons/ChangeLanguageBtn'

export default function MainPage() {
  useEffect(() => {
    const topMenuLink = document.getElementById('top-menu')
    const listener = window.addEventListener('scroll', e => {
      const scrollPositionY = e.target.scrollingElement.scrollTop
      if (scrollPositionY > 100) {
        topMenuLink.classList.add('active')
      } else {
        topMenuLink.classList.remove('active')
      }
    })
    // const video = document.getElementById('myVideo')
    // video.play()
    return () => window.removeEventListener('scroll', listener)
    
  }, [])

  const [selectedLang, setSelectedLang] = useState('RU')
  const switchLang = useCallback((lang) => {
    if (lang !== selectedLang) {
      if (lang === 'RU') {
        setSelectedLang('RU')
      } else if (lang === 'EN') {
        setSelectedLang('EN')
      }
    }
  }, [selectedLang])

  return (
    <div className='main-page-container'>
        <TopMenu lang={selectedLang}/>
        <PresentBlock
          imgUrl={MainBG}
          lang={selectedLang}
        />
        <AboutBlock lang={selectedLang}/>
        <EquipmentBlock lang={selectedLang}/>
        <ServicesBlock lang={selectedLang}/>
        <ContactFormBlock lang={selectedLang}/>
        <MapsBlock lang={selectedLang}/>
        <FooterBlock lang={selectedLang}/>
        <RollUpBtn link='#nav-link-1' />
        <ChangeLanguageBtn
          selected_lang={selectedLang}
          switchLang={switchLang}
        />
        {/* <ScrollToTopBtn /> */}
    </div>
  )
}
