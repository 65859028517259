import React from 'react'
import '../styles/components/EquipmentBlock.css'
import DescriptedImage from './DescriptedImage'
import Image2 from '../assets/oborud-1.jpg'
import Image3 from '../assets/oborud-2.jpg'
import Image4 from '../assets/oborud-3.jpg'
import { lit } from '../constants/literals'

export default function EquipmentBlock(props) {
  const {lang = 'RU'} = props
  return (
    <div className='equipment-block-container' id='nav-link-3'>
        <h1 className='block-title'>{lit?.[lang]?.title3}</h1>
        <DescriptedImage
          title={lit?.[lang]?.title4}
          description={lit?.[lang]?.desc2}
          switchElements
          enableDarkTheme
          imgUrl={Image2}
        />
        <DescriptedImage
          title={lit?.[lang]?.title5}
          description={lit?.[lang]?.desc3}
          enableDarkTheme
          imgUrl={Image3}
        />
        <DescriptedImage
          title={lit?.[lang]?.title6}
          description={lit?.[lang]?.desc4}
          switchElements
          enableDarkTheme
          imgUrl={Image4}
        />
    </div>
  )
}
